import { useState, useEffect } from 'react'
import { page_url,lang } from '../lang'

export default function Nav(props) {
    const result = props.nav_data.results;
    const parents = props.nav_data.parents;
    const styles = props.styles;


    // useEffect(()=>{
    //     console.log(props.nav_data);
    // },[]);

    return (
        <ul className={styles["nav-menu"]}>
            <li key={'nav-link-'+0}>
                <a href={page_url}>{lang('หน้าแรก','Home')}</a>
            </li>
            {
            (parents!==undefined&&parents.length>0) ?
            parents.map((item, index)=>(
                <li key={'nav-link-'+index}>
                    <a href={page_url+item.link.substring(1)}>{lang(item.menu_th,item.menu_en)}</a>
                </li>
            )):''}
            {(result!==undefined) ? (
            <li key={'nav-link-'+result.id}>
                <a href={(result.link!==undefined)?result.link:''}>{lang(result.menu_th,result.menu_en)}</a>
            </li> 
            ):''}
        </ul>
    )
}
