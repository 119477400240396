import { useState, useEffect } from 'react'
import api from '../api'
import axios from 'axios';
import { lang, booking_url } from '../lang'
import classes from '../../assets/css/form.module.css';

export default function Login(props) {
    const {styles, menu} = props
	const [login_email, setLoginEmail] = useState('');
	const [login_password, setLoginPassword] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [register, setRegister] = useState(false);
	const [name, setName] = useState('');
	const [faculty, setFaculty] = useState('');
	const [phone, setPhone] = useState('');
	const [ip, setIP] = useState('');
    const [error, setError] = useState(0);
    const [success, setSuccess] = useState(0);
    const [msg, setMsg] = useState('');

    useEffect(()=>{
        setError(0);
        setSuccess(0);
    },[register]);
    
    const fetchInit = async () =>{
        try {
            const res = await axios.get('https://geolocation-db.com/json/');
            setIP(res.data.IPv4);
        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }

    useEffect(() => {
        const mail =localStorage.getItem("email");
        // request access token 
        if(mail){
            window.location = process.env.PUBLIC_URL + '/rsvn?menu=mybooking';
        }
        fetchInit();
    },[menu]);

    const sendEmail = async (key) =>{
        let body = lang(`<h1>ระบบจองห้องอ่านเดี่ยว/ห้องอ่านกลุ่ม ห้องสมุดคณะพยาบาลศาสตร์ มช.</h1><p>เรียน ${name}</p><p>    ขณะนี้ท่านได้ทำการสมัครสมาชิกเพื่อเข้าสู่ระบบจองห้องอ่านเดี่ยว/ห้องอ่านกลุ่ม ห้องสมุดคณะพยาบาลศาสตร์ มหาวิทยาลัยเชียงใหม่ <br /><br />    โปรดคลิกลิงก์ยืนยันด้านล่างเพื่อเปิดใช้งานอีเมล์ของคุณและทำการจองห้องได้ที่ <a href="https://nurse.library.cmu.ac.th/rsvn?menu=activate&email=${email}&key=${key}">เปิดใช้งานบัญชี</a></p><br /><br /><small>**อีเมล์ฉบับนี้เป็นการส่งจากระบบอัตโนมัติ ไม่ต้องตอบกลับ**</small>`,
        `<h1>Faculty of Nursing Chiang Mai University Library - Booking System</h1><p>Welcome ${name}</p><p>    You're finish register for Faculty of Nursing Chiang Mai University Library - Booking System<br /><br />    Please click the verification link below to activate your account and make the reservation at <a href="https://nurse.library.cmu.ac.th/rsvn?menu=activate&email=${email}&key=${key}">CMUL user survey</a></p><br /><br /><small>**This email is sent from an automated system. do not reply**</small>`);
        const data =  {
            "SendProgram" : "usersurvey",
            "Email" : email,
            "EmailReplyTo": "nattavee.m@cmu.ac.th",
            "EmailSubject" : lang("ระบบจองห้องอ่านเดี่ยว/ห้องอ่านกลุ่ม ห้องสมุดคณะพยาบาลศาสตร์ มช.","Faculty of Nursing Chiang Mai University Library - Booking System"),
            "EmailBody" : body
        };  
        // console.log(data);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        await fetch('https://services.library.cmu.ac.th/api/email/SendEmailCMU.php', requestOptions)
        .then((res) => {
            console.log(res);
        });
    
      }
      
    
      const validateEmail = (mail) => {
        return String(mail)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    
    const validatePhone = (phone) => {
        // (123) 456-7890
        // (123)456-7890
        // 123-456-7890
        // 123.456.7890
        // 1234567890
        // +31636363634
        // 075-63546725
        return String(phone)
          .toLowerCase()
          .match(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
          );
      };
    
    
      const handleRegister = (e) => {
        e.preventDefault();
        setError(0);
        setSuccess(0);
        if(name===''){
            setError(1);
            setMsg(lang('กรุณากรอกชื่อของท่าน','Please enter your name..'));
        }else if(email===''){
            setError(1);
            setMsg(lang('กรุณากรอกอีเมล์ของท่าน','Please enter your email..'));
        }else if(!validateEmail(email)){
            setError(1);
            setMsg(lang('กรุณาตรวจสอบอีเมล์ของท่าน','Please check your email..'));
        }else if(password===''){
            setError(1);
            setMsg(lang('กรุณากรอกรหัสผ่านของท่าน','Please enter your password..'));
        }else if(phone!==''&&!validatePhone(phone)){
            setError(1);
            setMsg(lang('กรุณาตรวจสอบเบอร์โทรศัพท์ของท่าน','Please check your phone number..'));
        }else{
            // create user
            const jsonData = {
                email: email,
                name: name,
                faculty: faculty,
                password: password,
                phone: phone,
                user_info: '',
                active: 0,
                user: email,
                ip: ip,
            } 
            api.post(`rsvn_user/create`,jsonData)
            .then(response => {
                if(response.data.status==='ok'){
                    sendEmail(response.data.key);
                    setSuccess(1);
                    setMsg(lang('กรุณาตรวจสอบอีเมล์ของท่านเพื่อเปิดใช้งานอีเมล์ของคุณ','Please check your email for activate your account..'));
                    setEmail('');
                    setName('');
                    setFaculty('');
                    setPassword('');
                    setPhone('');
                }else{
                    setError(1);
                    let message = JSON.stringify(response.data.message);
                    setMsg(message);
                }
            })
            .catch(error => {
                setError(1);
                setMsg(error);
            });
        }
    }

    const handleLogin = (event) => {
        event.preventDefault();
        setError(0);
        setSuccess(0);
        if(login_email===''){
            setError(1);
            setMsg(lang('กรุณากรอกอีเมล์ของท่าน','Please enter your email..'));
        }else if(login_password===''){
            setError(1);
            setMsg(lang('กรุณากรอกรหัสผ่านของท่าน','Please enter your password..'));
        }else if(!validateEmail(login_email)){
            setError(1);
            setMsg(lang('กรุณาตรวจสอบอีเมล์ของท่าน1','Please check your email..'));
        }else{
            // check user
            const jsonData = {
                email: login_email,
                password: login_password,
            } 
            api.post(`rsvn_user/check`,jsonData)
            .then(response => {
                if(response.data.status==='ok'){
                    const result = response.data.result;
                    localStorage.setItem('email',login_email);
                    localStorage.setItem('ip',ip);
                    localStorage.setItem('cmu_account',JSON.stringify(result));
                    localStorage.setItem('token','');
                    localStorage.setItem('login_time',new Date().toISOString());
                    // update user session
                    let data = {
                        email: login_email,
                        ip: ip,
                        token: '',
                        user: login_email,
                        cmu_account: JSON.stringify(result),
                    }
                    api.post(`rsvn_user/session`,data)
                    .then(admin_session => {
                        if(admin_session.data.status==="ok"){
                            window.location = process.env.PUBLIC_URL + '/rsvn';
                        }else{
                            setError(1);
                            setMsg(admin_session.data.message);
                        }
                    })
                    .catch(error => {
                        setError(1);
                        setMsg(error);
                    });
                }else{
                    setError(1);
                    let message = JSON.stringify(response.data.message);
                    setMsg(message);
                }
            })
            .catch(error => {
                setError(1);
                setMsg(error);
            });
        }
    }

    const handleOAuth = (event) => {
        event.preventDefault();
        window.location = booking_url;
    }

    return (
        <div>
            <h3>
                {(register)?lang("สมัครสมาชิก","Create An Account"):lang("เข้าสู่ระบบ","Sign In")}
            </h3>
            {(error===1)?(
                <div className="notice danger">
                    <div><i className="fa-solid fa-triangle-exclamation"></i></div>
                    <div className="info">
                        <b>{(register)?lang('ไม่สามารถบันทึกข้อมูลได้','Can\'t Register..'):lang('ไม่สามารถเข้าสู่ระบบได้','Can\'t Sign in..')}</b><br />
                        {msg}
                    </div>
                </div>
                ):''}
            {(success===1)?(
            <div className="notice active">
                <div><i className="fa-regular fa-file-lines"></i></div>
                <div className="info">
                    <b>{(register)?lang('สมัครสมาชิกเรียบร้อย!','Successfully Register!'):lang('เข้าสู่ระบบเรียบร้อย!','Successfully Sign in!')}</b>
                    <br />{(register)?lang('กรุณาตรวจสอบอีเมล์ของท่าน เพื่อเปิดใช้งานอีเมล์นี้..','Please check your email for activate this account!'):lang('เข้าสู่ระบบเรียบร้อย กรุณารอสักครู่','Successfully Sign in..')}
                </div>
                <div>
                    <i className="fa-solid fa-xmark btn-close" onClick={(event)=>setSuccess(0)}></i>
                </div>
            </div>
            ):''}
            {(register)?(
            <div className={classes["booking-form"]}>
                <form className={classes["main-form"]}>
                    <fieldset>
                        <label htmlFor="email">{lang('อีเมล์',"Email")}</label>
                        <input type="text" name="email" value={email} onChange={e=>setEmail(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="password">{lang('รหัสผ่าน',"Password")}</label>
                        <input type="password" name="password" value={password} onChange={e=>setPassword(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="name">{lang('ชื่อ-นามสกุล',"Name")}</label>
                        <input type="text" name="name" value={name} onChange={e=>setName(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="faculty">{lang('หน่วยงานที่สังกัด',"Faculty")}</label>
                        <input type="text" name="faculty" value={faculty} onChange={e=>setFaculty(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="phone">{lang('หมายเลขโทรศัพท์',"Phone")}</label>
                        <input type="text" name="phone" value={phone} onChange={e=>setPhone(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <button onClick={e=>handleRegister(e)} className={classes["btn"]}>{lang('สมัครสมาชิก','Create An Account')}</button>
                        <button onClick={e=>setRegister(false)} className={classes["btn-light"]}>{lang('กลับหน้าหลัก','Back to Sign In')}</button>
                    </fieldset>
                </form>
            </div>
            ):(
            <div className={classes["booking-form"]}>
                <form className={classes["main-form"] + " " + classes["hr"]}> 
                    <p>
                        {lang("เข้าสู่ระบบด้วย CMU Account ","Sign In with CMU Account")}
                    </p>
                    <fieldset className={classes["center"]+" !w-full"}>
                        <button onClick={e=>handleOAuth(e)} className={classes["btn-login"]}><i className="fa-solid fa-right-to-bracket"></i>{lang('เข้าสู่ระบบด้วย CMU Account','Sign in with CMU Account')}</button>
                    </fieldset>
                </form>
                <p>
                    {lang("สำหรับบุคลากรภายนอก ","Or ")} 
                    <span onClick={e=>setRegister(true)} className={classes["link"]}>{lang("สมัครสมาชิก","Create An Account")}</span>
                    {lang(" หรือ "," ")} 
                </p>
                <form className={classes["main-form"]}>
                    <fieldset>
                        <label htmlFor="login_email">{lang('อีเมล์',"Email")}</label>
                        <input type="text" name="login_email" value={login_email} onChange={e=>setLoginEmail(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="login_password">{lang('รหัสผ่าน',"Password")}</label>
                        <input type="password" name="login_password" value={login_password} onChange={e=>setLoginPassword(e.target.value)} />
                    </fieldset>
                    <fieldset className={classes["right"]}>
                        <button onClick={e=>handleLogin(e)} className={classes["btn"]}>{lang('เข้าสู่ระบบ','Sign In')}</button>
                    </fieldset>
                </form>
            </div>
            )}
        </div>
    )
}
