import { useState, useEffect, lazy, Suspense, useTransition,useLayoutEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { chk_img } from '../components/lang'
import api from '../components/api'
import styles from '../assets/css/home.module.css';

import Slide from '../components/Homepage/Slide'
import Resource from '../components/Homepage/Resource'
import EBook from '../components/Homepage/Ebook'
// import News from '../components/Homepage/News'
import HomeCalendar from '../components/Homepage/HomeCalendar'
import Activity from '../components/Homepage/Activity'
import News from '../components/Homepage/News'

// const Slide = lazy(() => import ('../components/Homepage/Slide'))
// const Resource = lazy(() => import ('../components/Homepage/Resource'))
// const EBook = lazy(() => import ('../components/Homepage/Ebook'))
// const News = lazy(() => import ('../components/Homepage/News'))
// const HomeCalendar = lazy(() => import ('../components/Homepage/HomeCalendar'))
// const Activity = lazy(() => import ('../components/Homepage/Activity'))

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function Home() {
    const [init, setInit] = useState([]);
	const navigate = useNavigate();
    const [tab, setTab] = useState("news");
    const [book_style, setBookStyle] = useState({});
    const [news_style, setNewsStyle] = useState({});
    const [activity_style, setActivityStyle] = useState({});
    const [calensar_style, setCalendarStyle] = useState({});
    const [width, height] = useWindowSize();
    const [header_font, setHeaderFont] = useState("saruns");
    const [body_font, setBodyFont] = useState("kanit");
    // const [isAdmin, setIsAdmin] = useState(false);
    // const [isPending, startTransition] = useTransition();

    reveal();
    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                const response = await api.get('/setting');
                setInit(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
            try {
                const init_response = await api.get('/setting');
                setInit(init_response.data.results);
                const home_config = JSON.parse(init_response.data.results.config);
                localStorage.setItem("lannainfo_init",JSON.stringify(init_response.data.results));

                if(home_config.header_font==='Princess Galyani Vadhana IM'){
                    setHeaderFont("galyani !text-5xl");
                }else if(home_config.header_font==='Kanit'){
                    setHeaderFont("kanit");
                }else if(home_config.header_font==='TH Sarabun New'){
                    setHeaderFont("sarabun");
                }else if(home_config.header_font==='Saruns2477'){
                    setHeaderFont("saruns");
                }else if(home_config.header_font==='Pudgrong'){
                    setHeaderFont("pudgrong");
                }else{
                    setHeaderFont("sans-serif");
                }
                
                const response = await api.get('/article/1');
                const article = response.data.results;
                const detail = JSON.parse(article.detail);
                let book = {"background":detail.book_color}
                if(detail.book_bg){
                    book["backgroundImage"] = `url(${chk_img(detail.book_bg)})`;
                }
                setBookStyle(book);
                let news = {"background":detail.news_color}
                if(detail.news_bg){
                    news["backgroundImage"] = `url(${chk_img(detail.news_bg)})`;
                }
                setNewsStyle(news);
                let activity = {"background":detail.activity_color}
                if(detail.activity_bg){
                    activity["backgroundImage"] = `url(${chk_img(detail.activity_bg)})`;
                }
                setActivityStyle(activity);
                let calendar = {"background":detail.calendar_color}
                if(detail.calendar_bg){
                    calendar["backgroundImage"] = `url(${chk_img(detail.calendar_bg)})`;
                }
                setCalendarStyle(calendar);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
        window.addEventListener("scroll", reveal);
        reveal();
    },[]);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }

    return (
        <>
            <div className="homepage">
                {/* <Suspense fallback={<Preloader text="Slide" />}> */}
                    <div className="main-slide">
                        <Slide styles={styles} header_font={header_font} />
                    </div>
                {/* </Suspense>
                <Suspense fallback={<Preloader text="Book" />}> */}
                    {/* <section className="book reveal" style={book_style}> */}
                    <section className="book" style={book_style}>
                        <div className={"book-bg"}></div>
                        <EBook styles={styles} header_font={header_font} />
                    </section>
                {/* </Suspense> */}
                {/* {(tab==="news")? */}
                    <section className="activity" style={activity_style}>
                        <News styles={styles} setTab={setTab} header_font={header_font} />
                    </section>
                {/* : */}
                    <section className="calendar" style={news_style}>
                        <HomeCalendar styles={styles} init={init} setTab={setTab} header_font={header_font} />
                    </section>
                {/* } */}
                {/* <Suspense fallback={<h1>Resource</h1>}> */}
                    <section className="resource reveal" style={calensar_style}>
                        <Resource styles={styles} header_font={header_font} />
                    </section>
                 {/* </Suspense> */}
            </div>
        </>
    )
}