import { useState, useEffect} from "react"
import api from '../api'
import { chk_img, lang, gotoURL, gotoLink } from '../lang'
import { useNavigate } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import SwiperNavigation from "../Template/Swiper/SwiperNavigation";

SwiperCore.use([Navigation, Pagination, A11y]);
export default function Ebook(props) {
    const [resources, setResources] = useState([]);
    const [book_type, setBookType] = useState('book');
	const navigate = useNavigate();
    SwiperCore.use([Autoplay]); // start autoplay
    const styles = props.styles;
    const [activeIndex, setActiveIndex] = useState(0);
    const [first_book, setFirstBook] = useState(null);

    useEffect(()=>{
        const fetchBook = async () =>{
            try {
                const response = await api.get(`/book?show=12`);
                setResources(response.data.results);
                if(response.data.status===true&&response.data.results.length>0){
                    setFirstBook(response.data.results[0]);
                }
                // console.log(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
          }
        fetchBook();
    },[book_type]);

    useEffect(()=>{
        // console.log(first_book);
    },[first_book]);

    useEffect(()=>{
        const firstCard = resources[activeIndex];
        if(firstCard){
            let slide_imgs = document.querySelectorAll(".slide-scale");
            for (var i = 0; i < slide_imgs.length; i++) {
                slide_imgs[i].classList.remove("active");
            }
            let img = document.getElementById("slide-"+firstCard.id);
            img.classList.add("active");
            setFirstBook(firstCard);
            // if(img){
    
            // }
        }
    },[activeIndex]);

    return (
        <div className={"ebook container "+styles[book_type]}>
            <div className={styles["book-shelf-menu"]}>
                <h1 className={props.header_font}>New<span>Arrivals</span></h1>
                {/* <h2>New<span> Arrival</span></h2> */}
                <h4 className={styles["book-title"]}>{(first_book)?first_book.title:''}</h4>
                <div className={styles["book-detail"]}>
                    <p>{lang('ผู้แต่ง','Author')}: <span>{(first_book&&first_book.author!=="")?first_book.author:'-'}</span></p>
                    <p>{lang('เลขหมู่','Classification')}: <span>{(first_book&&first_book.classification!=="")?first_book.classification:'-'}</span></p>
                </div>
                <div className={styles["book-shelf-desktop"]}>
                <SwiperNavigation section="ebook" />
                </div>
            </div>
            <div className={styles["book-shelf-mobile"]}>
                <ul className={styles["ebook-card"]}>
                {resources.map((item, key) =>(
                    <li key={key}>
                        <a href={item.link} target={item.target}>
                            <div className={styles["cover"]+" group"}>
                                <img src={chk_img(item.img_url)} alt={lang(item.title,item.title)} width="154" height="202" />
                            </div>
                            <span>{item.title}</span>
                        </a>
                    </li>
                ))}
                </ul>
                <div className={styles["readmore"]} onClick={() => navigate('/books')}>{lang('ดูทั้งหมด','View All')}</div>
            </div>
            <div className={styles["book-shelf"]}>
                <div className={styles["ebook-card"]}>
                {/* group items  */}
                <Swiper
                    onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                    slidesPerView={6}
                    centeredSlides={false}
                    speed={900}
                    // autoplay={{ delay: 3000 }}
                    // loop={true}
                    // grabCursor={true}
                    breakpoints={{
                      // when window width is >= 320px
                      640: {
                        slidesPerView: 2,
                      },
                      // when window width is >= 640px
                      768: {
                        slidesPerView: 3,
                      },
                      // when window width is >= 992
                      1024: {
                        slidesPerView: 3,
                      },
                      // when window width is >= 992
                      1240: {
                        slidesPerView: 4,
                      },
                      1521: {
                        slidesPerView: 5,
                      }
                    }}
                    // navigation={{
                    //   nextEl: ".swiper-button-next-unique",
                    //   prevEl: ".swiper-button-prev-unique"
                    // }}
                    // pagination={{
                    //     el: ".ebook-swiper-pagination",
                    //     type: "bullets",
                    //     clickable: true
                    // }}
                    navigation={{
                      nextEl: ".art-ebook-swiper-next",
                      prevEl: ".art-ebook-swiper-prev"
                    }}
                    // modules={[Pagination]}
                    effect={"fade"}
                    className="ebook-swiper"
                >
                    {resources.map((item, index) => (
                        <SwiperSlide className="swiper-slide" key={index}>
                            <div className={styles["card"]+" group"}>
                            {(item.book_type==="e-book"||item.book_type==="e-journal")?(
                                <div className={styles["cover"]+" group"}>
                                    <img src={chk_img(item.img_url)} className={`slide-scale ${(index===0)?'active':''}`} id={"slide-"+item.id} onClick={e=>gotoLink(item.link)} alt={lang(item.title,item.title)} width="154" height="202" />
                                    <span onClick={e=>gotoLink(item.link)}>{item.title}</span>
                                </div>
                            ):(
                                <div className={styles["cover"]+" group"}>
                                    <img src={chk_img(item.img_url)} className={`slide-scale ${(index===0)?'active':''}`} id={"slide-"+item.id} onClick={e=>gotoURL("/book/"+item.id)} alt={lang(item.title,item.title)} width="154" height="202" />
                                    <span onClick={e=>gotoURL("/book/"+item.id)}>{item.title}</span>
                                </div>
                            )}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                    {/* <div className="swiper-button-prev-unique"><FaChevronLeft /></div>
                    <div className="swiper-button-next-unique"><FaChevronRight /></div> */}
                </div>
                <div className={styles["shelf"]}></div>
                <div className={styles["readmore"]} onClick={() => navigate('/resources')}>{lang('ดูทั้งหมด','View All')}</div>
            </div>
        </div>
    )
}
