import { useState, useEffect} from "react"
import React from 'react'
import api from '../api'
import { chk_img, lang } from '../lang'

export default function Resource(props) {
    const [resources, setResources] = useState([]);
    const [header_font, setHeaderFont] = useState("galyani !text-5xl");
    const styles = props.styles;
    useEffect(()=>{
        const fetchInit = async () =>{
            try {
              const response = await api.get('/menu?type=knowledge-menu');
              setResources(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
          }
        fetchInit();
    },[]);
    return (
        <div className={"container "+styles["resource"]}>
            <h2 className={props.header_font}>{lang("แหล่งข้อมูล","Website ")}<span>{lang("ที่เกี่ยวข้อง","Related")}</span></h2>
            <ul className={styles["resource-card"]}>
            {resources.map((item, key) =>(
                <li key={key}>
                    <a href={item.link} target={item.target}>
                        <div className={styles["cover"]}><img src={chk_img(item.cover_img)} alt={lang(item.menu_th,item.menu_en)} height="50" /></div>
                    </a>
                </li>
            ))}
            </ul>
        </div>
    )
}
