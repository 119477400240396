import React, { useState, useEffect } from 'react'
import api from '../api'
import { lang, gotoURL } from '../lang'
import FullCalendar from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid' 
import interactionPlugin from "@fullcalendar/interaction";
import thLocale from '@fullcalendar/core/locales/th';
import Alert from "sweetalert2";

export default function Calendar(props) {
    const {events, eventClick, renderEventContent} = props;
    
    const [language, setLanguage] = useState(thLocale);

    useEffect(()=>{
        let lang = localStorage.getItem("lang");
        if(lang==="en"){
            setLanguage(null);
        }else{
            setLanguage(thLocale);
        }
    },[]);

    // useEffect(()=>{
    //     console.log(events);
    // },[events]);

  return (
    <FullCalendar
        headerToolbar={{
            left: '',
            center: 'prev title next',
            right: `today`
        }} 
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth'
        events={events}
        editable={true}
        eventClick={eventClick}
        eventContent={renderEventContent} 
        locale={language}
        eventTimeFormat={{hour: '2-digit',minute: '2-digit', hour12: false ,meridiem: false}}
        dayMaxEventRows={1}
        navLinks={false}
        // firstDay={0}
    />
  )
}
