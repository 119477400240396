import React, { Suspense, useEffect, useState, useRef } from "react"
import { Outlet,useLocation } from "react-router-dom"
import api from './api'
import { lang, getTextSize, cookies, img_url, page_url } from './lang'
import Footer from './Footer'
import ScrollToTop from './ScrollToTop';
import layout from '../assets/css/layout.module.css';
import footer_styles from '../assets/css/footer.module.css';
import CookieBanner from './CookieBanner';  
import Preloader from './Template/Preloader'
import Menu from './Template/NavMenu';
import { FaSearch } from "react-icons/fa";
// import ReactGA, { GA4 } from "react-ga4";

const Layout = () => {
    const [menus, setMenus] = useState([]);
    const [init, setInit] = useState([]);
    const [language, setLang] = useState("th");
    const [font_size, setFontSize] = useState("small");
    const [mobile_menu, setMobileMenu] = useState(false);
    const [chk_menu, setChkMenu] = useState(1);
    const [header_color, setHeaderColor] = useState("#fff");
    const [header_font, setHeaderFont] = useState("saruns");
    const [body_font, setBodyFont] = useState("kanit");
    const [bg_color, setBGColor] = useState("#fff");
    const location = useLocation();
    const header_ref = useRef(null);
    const body_ref = useRef(null);
  
    const [search_type, setSearchType] = useState('search website');
    const [keyword, setKeyword] = useState('');

    useEffect(()=>{
        setLang(prev => localStorage.getItem("lang"));
        const fetchMenus = async () =>{
          try {
            let data = {
              type: "%main-menu%",
              order: "parent_menu_id,order asc"
            }
            const response = await api.post('/menu',data);
            let menu = [];
            let menu_index = [];
            response.data.results.forEach(item => {
                if(!menu_index.includes(item.id)){
                  menu_index.push(item.id);
                }
                let menu_active = false;
                if(location.pathname===item.link){
                  menu_active = true;
                  if(menu[menu_index.indexOf(item.parent_menu_id)]){
                    menu[menu_index.indexOf(item.parent_menu_id)].active = true;
                  }
                }
                if(item.parent_menu_id==="0"){
                  menu[menu_index.indexOf(item.id)] = {
                    menu_id: item.id,
                    menu_th: item.menu_th,
                    menu_en: item.menu_en,
                    link: item.link,
                    menu_type: item.menu_type,
                    order: item.order,
                    target: item.target,
                    active: menu_active,
                    show: menu_active,
                    child: []
                  }
                }else{
                  let tmp = {
                    menu_id: item.id,
                    menu_th: item.menu_th,
                    menu_en: item.menu_en,
                    link: item.link,
                    menu_type: item.menu_type,
                    order: item.order,
                    target: item.target,
                    active: menu_active,
                    show: menu_active,
                    child: []
                  };
                  if(menu_index.indexOf(item.parent_menu_id).child){
                    menu[menu_index.indexOf(item.parent_menu_id)].child.push(tmp);
                  }else{
                    menu.forEach((element,menu_index) => {
                      element.child.forEach((item,child_index) => {
                        if(item.menu_id === item.parent_menu_id){
                          menu[menu_index].child[child_index].child.push(tmp);
                        }
                      });
                    });
                  }
                }
            });
            setMenus(menu);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
  
          try {
            const response = await api.get('/setting');
            setInit(response.data.results);
            const home_config = JSON.parse(response.data.results.config);
            localStorage.setItem("lannainfo_init",JSON.stringify(response.data.results));
            setHeaderColor(home_config.header_color);
            setBGColor(home_config.bg_color);
            if(home_config.body_font==='Princess Galyani Vadhana IM'){
              setBodyFont("galyani");
            }else if(home_config.body_font==='Kanit'){
              setBodyFont("kanit");
            }else if(home_config.body_font==='Saruns2477'){
              setBodyFont("saruns");
            }else if(home_config.body_font==='TH Sarabun New'){
              setBodyFont("sarabun");
            }else if(home_config.body_font==='Pudgrong'){
              setBodyFont("pudgrong");
            }else{
              setBodyFont("sans-serif");
            }
            
            if(home_config.header_font==='Princess Galyani Vadhana IM'){
                setHeaderFont("galyani !text-5xl");
            }else if(home_config.header_font==='Kanit'){
                setHeaderFont("kanit");
            }else if(home_config.header_font==='TH Sarabun New'){
                setHeaderFont("sarabun");
            }else if(home_config.header_font==='Saruns2477'){
                setHeaderFont("saruns");
            }else if(home_config.header_font==='Pudgrong'){
                setHeaderFont("pudgrong");
            }else{
                setHeaderFont("sans-serif");
            }
            
            // console.log(home_config.header_color);
            // console.log(home_config.bg_color);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
        }
        fetchMenus();

        let text_size = cookies.get('text-size');
        setFontSize(text_size);
    },[]);

    window.addEventListener('scroll', (event) => {
      if(header_ref.current){
        if (window.scrollY > 5) {
          header_ref.current.className = layout["drop-shadow"];
        } else {
          header_ref.current.className = layout["shadow-none"];
        }
      }
    });

    const setLanguage = (lang) => {
        setLang(prev => lang);
        localStorage.setItem("lang", lang);
        window.location.reload(false);
    }

    const setFont = (text_size) => {
      setFontSize(prev => text_size);
      cookies.set('text-size', text_size, { path: '/' });
      body_ref.current.ClassName = getTextSize();
    }

    const setActive = (e,menu_id) => {
      setMenus(prev => {
          let tmp = prev;
          tmp.forEach(item => {
              item.active = false;
                if(item.menu_id === menu_id){
                    item.active = true;
                    item.show = true;
                }
                let submenus = item.child;
                submenus.forEach(element => {
                    element.active = false;
                    if(element.menu_id === menu_id){
                        item.active = true;
                        element.active = true;
                        element.show = true;
                    }
                });
          });
          return tmp;
      });
      setChkMenu(menu_id);
    }

    const handleSearch = () => {
      // e.preventDefault();
      // if(search_type==='OPAC'){
      //     window.open("https://search.lib.cmu.ac.th/search/?searchtype=Y&searcharg="+keyword, "_blank");
        if(search_type==='discovery'){
          window.open("https://cmul.primo.exlibrisgroup.com/discovery/search?query=any,contains,"+keyword+"&tab=MainLibrary&search_scope=MainLibrary&vid=66CMU_INST:cmul&offset=0", "_blank");
      }else if(search_type==='Worldwide Search & Request'){
          window.open("https://cmu.on.worldcat.org/external-search?queryString="+keyword+"&lang=th&clusterResults=on&stickyFacetsChecked=on", "_blank");
      }else if(search_type==='EDS'){
          window.open("https://discovery.ebsco.com/c/n2jqxu/results?q="+keyword, "_blank");
      }else if(search_type==='PULINET'){
          window.open("https://search.ebscohost.com/login.aspx?bquery="+keyword+"&submit=Search&direct=true&scope=site&site=eds-live&authtype=ip%2Cguest&custid=s5150876&groupid=main&profile=ill&defaultdb=&option=1", "_blank");
      }else if(search_type==='Digital Collections'){
          document.getElementById('formCMUDC').submit();
      }else if(search_type==='CMU Intellectual Repository'){
          window.open("http://cmuir.cmu.ac.th/simple-search?query="+keyword, "_blank");
      }else if(search_type==='search website'){
          window.location = page_url+"news?keyword="+keyword;
      }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    return (
      <div ref={body_ref} className={getTextSize()+' '+body_font}>
      <header ref={header_ref} className={layout["shadow-none"]} style={{backgroundColor: header_color}}>
          {(init.name_th!==undefined)?
          <nav className={layout["nav"]}>
            <div className={layout["nav-container"]}>
                <div className={layout["logo"]}>
                  <div className={layout["logo-img"]}>
                  <a href={process.env.PUBLIC_URL + "/"}><img src={img_url+lang(init.logo_th,init.logo_en)} alt={lang(init.name_th,init.name_en)} height="80" /></a>
                  <div className="header-info">
                    <h3 className={header_font} dangerouslySetInnerHTML={{__html: lang(init.name_th,init.name_en)}}></h3>
                  </div>
                  </div>
                  <div className={layout["top-nav"]}>
                    <div className={layout["tool"]}>
                      <ul className={layout["language"]}>
                          <li className={language==="th"?layout.active:''}><button onClick={()=>setLanguage("th")}>TH</button></li>
                          <li className={language==="en"?layout.active:''}><button onClick={()=>setLanguage("en")}>EN</button></li>
                      </ul>
                      <ul className={layout["font-size"]}>
                          <li className={font_size==="small"?layout.active:''}><button onClick={()=>setFont("small")}>ก</button></li>
                          <li className={font_size==="normal"?layout.active:''}><button onClick={()=>setFont("normal")}>ก</button></li>
                          <li className={font_size==="large"?layout.active:''}><button onClick={()=>setFont("large")}>ก</button></li>
                      </ul>
                    </div>
                    <div className={layout["search-tool"]}>
                      <fieldset>
                          <select name="search_type" id="search_type" value={search_type} onChange={e=>setSearchType(e.target.value)}>
                              <option value="discovery">PRIMO</option>
                              <option value="Worldwide Search & Request">Worldwide Search & Request</option>
                              <option value="EDS">EDS</option>
                              <option value="PULINET">PULINET</option>
                              <option value="Digital Collections">Digital Collections</option>
                              <option value="CMU Intellectual Repository">CMU Intellectual Repository</option>
                              <option value="search website">{lang('ค้นหาภายในเว็บ','Search in Website')}</option>
                          </select>
                          <input type="text" name="keyword" id="keyword" value={keyword} onChange={e=>setKeyword(e.target.value)} onKeyDown={handleKeyDown} placeholder={lang('ค้นหา','Keyword')} />
                          <button type="button" onClick={e=>handleSearch()}><FaSearch /></button>
                      </fieldset>
                      <form action="https://cmudc.library.cmu.ac.th/frontend/Search" method="post" target="_blank" id="formCMUDC">
                          <input id="input_cmu_dc" name="ex_query[main]" type="hidden" value={keyword} />
                          <input type="hidden" name="ex_query[option]" value="all" />
                      </form>
                    </div>
                  </div>
                </div>
                <Menu language={language} setLanguage={setLanguage} init={init} layout={layout} header_color={header_color} font_size={font_size} setFont={setFont} />
            </div>
          </nav>
          :''}
      </header>
      {/* <section style={{"background":bg_color}}> */}
          {(init.name_th!==undefined)?
          <Suspense fallback={<Preloader text="" />}>
            <Outlet />
          </Suspense>
          :''}
          {/* <!--begin::Scrolltop Button--> */}
          <ScrollToTop />
          {/* <!--end::Scrolltop Button--> */}
      {/* </section> */}
          {(init.name_th!==undefined)?
      <div className={footer_styles.footer} style={{backgroundColor: bg_color}}>
        <Footer init={init} styles={footer_styles}/>
      </div>
          :''}
      <CookieBanner />
      </div>
    );
}

export default Layout