import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { lang, page_url, img_url, gotoURL, thDateFormat, enDateFormat } from '../lang'
import api from '../api'
import classes from '../../assets/css/calendar.module.css'
import Calendar from "../Booking/Calendar"
import Alert from "sweetalert2";

export default function HomeCalendar(props) {
    const {styles, init} = props;
    const navigate = useNavigate();
    const [header_font, setHeaderFont] = useState("saruns");
    const [body_font, setBodyFont] = useState("kanit");

    const [events, setEvents] = useState([]);
    const category_id = "2:3";

    useEffect(()=>{
        // fetchInit();
        let init = JSON.parse(localStorage.getItem("lannainfo_init"));
        let home_config = JSON.parse(init.config);

        if(home_config.header_font==='Princess Galyani Vadhana IM'){
            setHeaderFont("galyani !text-5xl");
        }else if(home_config.header_font==='Kanit'){
            setHeaderFont("kanit");
        }else if(home_config.header_font==='TH Sarabun New'){
            setHeaderFont("sarabun");
        }else if(home_config.header_font==='Saruns2477'){
            setHeaderFont("saruns");
        }else{
            setHeaderFont("sans-serif");
        }

        if(home_config.body_font==='Princess Galyani Vadhana IM'){
            setBodyFont("galyani");
        }else if(home_config.body_font==='Kanit'){
            setBodyFont("kanit");
        }else if(home_config.body_font==='Saruns2477'){
            setBodyFont("saruns");
        }else if(home_config.body_font==='TH Sarabun New'){
            setBodyFont("sarabun");
        }else{
            setBodyFont("sans-serif");
        }
        fetchData();
    },[]);

    const fetchData = async () =>{
        try {
            const response = await api.get('/article/category/'+category_id+'');
            const results = response.data.results;
            // console.log(results);
            let all_events = [];
            results.forEach(element => {
                if((element.detail)){
                    let article_config = JSON.parse(element.detail);
                    if(article_config.start_date){
                        let start_date = article_config.start_date;
                        let end_date = article_config.end_date;
                        start_date = (article_config.start_time)?start_date+"T"+article_config.start_time+':00':new Date(start_date);
                        end_date = (article_config.end_time)?end_date+"T"+article_config.end_time+':00':new Date(end_date);
                        let item = {
                            title: lang(element.title_th,element.title_en),
                            start  : start_date,
                            end  : end_date,
                            item  : element,
                            type  : "event",
                        }
                        all_events.push(item);
                    }

                }
            });
            setEvents(all_events);
        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }

    /**
     * when we click on event we are displaying event details
     */
    const eventClick = (eventClick) => {
        if(eventClick.event.extendedProps.type==="event"){
            let item = eventClick.event.extendedProps.item;
            let article_config = JSON.parse(item.detail);
            let start_date = article_config.start_date;
            let end_date = article_config.end_date;
            let start_time = (article_config.start_time)?article_config.start_time:'00:00';
            let end_time = (article_config.end_time)?article_config.end_time:'00:00';
            if(start_date!==end_date && end_time==='00:00'){
                end_time = "23:59";
            }
            Alert.fire({
                title: eventClick.event.title,
                html:
                    `<div class="table-responsive">
                <table class="table ${body_font}">
                <tbody>
                    <tr>
                        <td colspan="2"><img src=${img_url + item.cover_img} title=${lang(item.title_th,item.title_en)}></td>
                    </tr>
                    <tr>
                        <td class="nowrap">${lang('รายการ','Title')}</td>
                        <td><strong>` + eventClick.event.title + `</strong></td>
                    </tr>
                    <tr>
                        <td class="nowrap">${lang('วันที่','Date')}</td>
                        <td><strong>` + lang(thDateFormat(start_date),enDateFormat(start_date)) + `</strong></td>
                    </tr>
                    ${(start_date!==end_date)?
                    `<tr>
                        <td class="nowrap">${lang('ถึงวันที่','To Date')}</td>
                        <td><strong>${lang(thDateFormat(end_date),enDateFormat(end_date))}</strong></td>
                    </tr>`
                    :''}
                    ${(start_time!=='00:00')?
                    `<tr>
                        <td class="nowrap">${lang('เวลา','Time')}</td>
                        <td><strong>${start_time} - ${end_time}</strong></td>
                    </tr>`
                    :''}
                    <tr>
                        <td class="nowrap">${lang('รายละเอียด','Detail')}</td>
                        <td><strong>` + lang(item.info_th,item.info_en) + `</strong></td>
                    </tr>
                </tbody>
                </table>
                </div>`,
                customClass: {
                    confirmButton: body_font,
                    cancelButton: body_font,
                    title: header_font+' text-pallate-2'
                },
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonColor: "#EC7D2F",
                cancelButtonColor: "#92929f",
                confirmButtonText: lang("รายละเอียด","View More"),
                cancelButtonText: lang("ปิดหน้าต่าง","Close"),
            }).then(result => {
                if (result.isConfirmed) {
                    eventClick.event.remove(); // It will remove event from the calendar
                    gotoURL('/article/'+item.id);
                }
                // if (result.isConfirmed) {
                // //   eventClick.event.remove(); // It will remove event from the calendar
                //     openModal(item.id);
                // }else if (result.isDenied) {
                //     //   eventClick.event.remove(); // It will remove event from the calendar
                //         openRmModal(item.id);
                //     }
            });
        }else{
            let item = eventClick.event.extendedProps.item;
            let holiday = lang(thDateFormat(item.start),enDateFormat(item.start)) + ' - ' + lang(thDateFormat(item.end),enDateFormat(item.end));
            if(item.start === item.end){
                holiday = lang(thDateFormat(item.start),enDateFormat(item.start));
            }
            Alert.fire({
                title: eventClick.event.title,
                html:
                    `<div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>${lang('รายการ','Title')}</td>
                            <td><strong>` + eventClick.event.title + `</strong></td>
                        </tr>
                        <tr>
                            <td>${lang('วันที่','Date')}</td>
                            <td><strong>` + holiday + `</strong></td>
                        </tr>
                    </tbody>
                </table>
                </div>`,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: "#3085d6",
                cancelButtonText: lang("ปิดหน้าต่าง","Close")
            }).then(result => {
            });
        }
    };

    // a custom render function
    function renderEventContent(eventInfo) {
        var e = document.getElementsByClassName('fc-col-header-cell-cushion');
        if(e){
            Array.from(e).forEach(element => {
                if(element.localName==="a"){
                    let d = document.createElement('div');
                    d.innerHTML = element.innerHTML;
                    element.parentNode.innerHTML=d.innerHTML;
                }
            });
        }
        return (
        <>
            {/* <div className="fc-event-time">{eventInfo.timeText}</div> */}
            <div className="fc-event-title">{eventInfo.event.title}</div>
        </>
        )
    }

  return (
    <div className={"container "+styles["calendar"]}>
        <div className={styles["calendar-detail"]}>
            <div className={styles["calendar-group"]}>
                {/* <h2 className={props.header_font} onClick={e=>{e.preventDefault();props.setTab("news")}}>{lang("ข่าวสาร","News ")}<span>{lang("ที่น่าสนใจ","and Activities")}</span></h2> */}
                <h2 className={props.header_font}>{lang("ข่าว","Activity ")}<span>{lang("กิจกรรม","Calendar")}</span></h2>
                <div className={classes["calendar"]+" calendar "+styles["frame"]}>
                        <Calendar events={events} showButton={true} eventClick={eventClick} renderEventContent={renderEventContent}/>
                </div>
            </div>
        </div>
    </div>
  )
}
