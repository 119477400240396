import {useState, useEffect} from "react"
import { lang } from './lang'
import { FaPhoneAlt, FaFacebookF, FaEnvelope, FaTwitter, FaYoutube, FaTiktok } from 'react-icons/fa';
import { BsLine } from "react-icons/bs";
import api from './api'

const Footer = (props) => {
    const init = props.init;
    const styles = props.styles;
    const [header_font, setHeaderFont] = useState("saruns");

    useEffect(()=>{
        const init = JSON.parse(localStorage.getItem("lannainfo_init"));
        const home_config = JSON.parse(init.config);

        if(home_config.header_font==='Princess Galyani Vadhana IM'){
            setHeaderFont("galyani !text-5xl");
        }else if(home_config.header_font==='Kanit'){
            setHeaderFont("kanit");
        }else if(home_config.header_font==='TH Sarabun New'){
            setHeaderFont("sarabun");
        }else if(home_config.header_font==='Saruns2477'){
            setHeaderFont("saruns");
        }else if(home_config.header_font==='Pudgrong'){
            setHeaderFont("pudgrong");
        }else{
            setHeaderFont("sans-serif");
        }
    },[]);

    return (
        <>
        <div className={"container footer "+styles["footer-container"]}>
            <div className={styles["footer-info"]}>
                <h4 className={header_font}>{lang('ติดต่อเรา','Contact us')}</h4>
                <p dangerouslySetInnerHTML={{__html: lang(init.address_th,init.address_en)}}></p>
            </div>
            {(init.map)?(
            <div className={styles["map"]}><iframe name="footer-map" src={init.map} title={lang(init.name_th,init.name_en,init.name_cn)}></iframe></div>
            ):''}
            <div className={styles["footer-info"]}>
                <h4 className={header_font}>{lang('เวลาเปิดให้บริการ','Open hour')}</h4>
                <span dangerouslySetInnerHTML={{__html: lang(init.open_info_th,init.open_info_en)}}></span>
            </div>
            <div className={styles["footer-info"]}>
                <h4 className={header_font}>{lang('ช่องทางการสื่อสาร','Contact us')}</h4>
                <span dangerouslySetInnerHTML={{__html: init.footer_phone}}></span>
                <div className="right">
                    <ul className={styles["social-link"]}>
                        <li><a href={init.facebook} target="_blank" rel="noreferrer" aria-label="Facebook" className="rounded-btn"><FaFacebookF /></a></li>
                        <li><a href={init.youtube} target="_blank" rel="noreferrer" aria-label="Youtube" className="rounded-btn"><FaYoutube /></a></li>
                        <li><a href={`mailto:`+init.email} aria-label="Email" className="rounded-btn"><FaEnvelope /></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className={"container "+styles["copyright"]}>
            <div className="left">
                <p>{lang('© สงวนลิขสิทธิ์ เว็บไซต์'+init.name_th+'โดย สำนักหอสมุด มหาวิทยาลัยเชียงใหม่','Copyright © 2022 Chiang Mai University Library. All rights reserved','Copyright © 2022 Chiang Mai University Library. All rights reserved')}</p>
                <a href={process.env.PUBLIC_URL + "/panel"} target="_blank" rel="noopener noreferrer">(ระบบหลังบ้าน)</a>
            </div>
        </div>
        </>
    )
}

export default Footer