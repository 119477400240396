import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import NewsCard from "./NewsCard";
import SwiperNavigation from "../Template/Swiper/SwiperNavigation";
import { lang } from '../lang'
import api from '../api'

SwiperCore.use([Navigation, Pagination, A11y]);
export default function News(props) {
    const styles = props.styles;
	const navigate = useNavigate();
    SwiperCore.use([Autoplay]); // start autoplay
    const [article, setArticle] = useState([]);
    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                const response = await api.get('/article/category/3');
                setArticle(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[]);

    return (
        <div className={"container "+styles["news"]}>
            <h2 className={props.header_font}>{lang("ข่าวสาร","News ")}<span>{lang("ที่น่าสนใจ","and Activities")}</span></h2>
            {/* <h2 className={props.header_font} onClick={e=>{e.preventDefault();props.setTab("activity")}}>{lang("ข่าว","Activity ")}<span>{lang("กิจกรรม","Calendar")}</span></h2> */}
            <div className="news-container">
                {/* group items  */}
                <Swiper
                    spaceBetween={5}
                    slidesPerView={1}
                    speed={900}
                    autoplay={{ delay: 3000 }}
                    modules={[Pagination]}
                    pagination={{
                        el: ".group-swiper-pagination",
                        type: "bullets",
                        clickable: true
                    }}
                    navigation={{
                      nextEl: ".art-group-swiper-next",
                      prevEl: ".art-group-swiper-prev"
                    }}
                    effect={"fade"}
                    className="group-swiper"
                >
                    {article.map((item, index) => (
                        <SwiperSlide className="swiper-slide" key={index}>
                            <NewsCard key={index} item={item} styles={styles} header_font={props.header_font} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <SwiperNavigation section="group" />
        
            </div>
            <div className={styles["readmore"]}>
                <button onClick={() => navigate('/news')}>{lang("ดูเพิ่มเติม","View more")}</button></div>
        </div>
    )
}
